<template>
  <div class="container container-sm-lot pt-1">
    <div>
      <div class="card-result my-3">
        <div class="text-center">
          <div class="text-[34px] font-medium text-warning2 mb-0"> รางวัลที่ 1 </div>
          <p class="mb-0 text-blue3 font-medium"> 1 รางวัล ชุดละ 1,125,000,000 วอน </p>
          <div class="my-1 text-blue3 text-2xl font-medium">
            {{ result1st }}
          </div>
        </div>
      </div>
      <div class="card-result mb-3">
        <div class="text-center">
          <h4 class="mb-0 font-medium text-warning2"> รางวัลเลขหน้า 3 ตัว </h4>
          <p class="mb-0 text-blue3 font-medium"> 2 รางวัล ชุดละละ 750,000 วอน </p>
        </div>
        <div class="row my-1 text-blue3 font-medium">
          <div v-for="(item, index) in resultFirst3" :key="index" class="col-6 text-center">
            {{ item.result }}
          </div>
        </div>
      </div>
      <div class="card-result mb-3">
        <div class="text-center">
          <h4 class="mb-0 text-warning2"> รางวัลเลขท้าย 3 ตัว </h4>
          <p class="mb-0 text-blue3 font-medium"> 2 รางวัล ชุดละ 750,000 วอน </p>
        </div>
        <div class="row my-1 text-blue3 font-medium">
          <div v-for="(item, index) in resultLast3" :key="index" class="col-6 text-center">
            {{ item.result }}
          </div>
        </div>
      </div>
      <div class="card-result mb-3">
        <div class="text-center">
          <h4 class="mb-0 text-warning2"> รางวัลเลขท้าย 2 ตัว </h4>
          <p class="mb-0 text-blue3 font-medium"> 1 รางวัล ชุดละ 375,000 วอน </p>
          <div class="d-flex align-items-center justify-content-center my-1 text-blue3 font-medium">
            <span v-for="(item, index) in resultLast2" :key="index">
              {{ item.result }}
            </span>
          </div>
        </div>
      </div>
      <div class="card-result mb-3">
        <div class="text-center">
          <h4 class="mb-0 text-warning2"> รางวัลข้างเคียงรางวัลที่ 1 </h4>
          <p class="mb-0 text-blue3 font-medium"> 2 รางวัล ชุดละ 18,450,000 วอน </p>
          <div class="row my-1 text-blue3 font-medium">
            <div v-for="(item, index) in resultNear1st" :key="index" class="col-6 text-center">
              {{ item.result }}
            </div>
          </div>
        </div>
      </div>
      <div class="card-result mb-3">
        <div>
          <h4 class="mb-0 text-warning2"> รางวัลที่ 2 </h4>
          <p class="mb-0 text-blue3 font-medium"> 5 รางวัล ชุดละ 37,500,000 วอน </p>
          <div class="row my-1 text-blue3 font-medium">
            <div v-for="(item, index) in result2nd" :key="index" class="col-3">
              {{ item.result }}
            </div>
          </div>
        </div>
      </div>
      <div class="card-result mb-3">
        <div>
          <h4 class="mb-0 text-warning2"> รางวัลที่ 3 </h4>
          <p class="mb-0 text-blue3 font-medium"> 10 รางวัล ชุดละ 15,000,000 วอน </p>
          <div class="row gap-1 my-1 text-blue3 font-medium">
            <div v-for="(item, index) in result3rd" :key="index" class="col-3 mb-2">
              {{ item.result }}
            </div>
          </div>
        </div>
      </div>
      <div class="card-result mb-3">
        <div>
          <h4 class="mb-0 text-warning2"> รางวัลที่ 4 </h4>
          <p class="mb-0 text-blue3 font-medium"> 50 รางวัล ชุดละ 7,500,000 วอน </p>
          <div class="row text-blue3 font-medium">
            <div v-for="(item, index) in result4th" :key="index" class="col-3 mb-2">
              {{ item.result }}
            </div>
          </div>
        </div>
      </div>
      <div class="card-result mb-3">
        <div>
          <h4 class="mb-0 text-warning2"> รางวัลที่ 5 </h4>
          <p class="mb-0 text-blue3 text-blue3 font-medium"> 100 รางวัลๆละ 750,000 วอน </p>
          <div class="row text-blue3 font-medium">
            <div v-for="(item, index) in result5th" :key="index" class="col-3 mb-2">
              {{ item.result }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  layout: 'lottery',
  data() {
    return {
      rounds: [],
      results: [],
      months: [
        'มกราคม',
        'กุมภาพันธ์',
        'มีนาคม',
        'เมษายน',
        'พฤษภาคม',
        'มิถุนายน',
        'กรกฎาคม',
        'สิงหาคม',
        'กันยายน',
        'ตุลาคม',
        'พฤศจิกายน',
        'ธันวาคม',
      ],
    }
  },
  computed: {
    result1st() {
      return this.results.find(item => item.code === 'SALAK_1ST')?.result
    },
    resultFirst3() {
      return this.results.filter(item => item.code === 'SALAK_FIRST_3')
    },
    resultLast3() {
      return this.results.filter(item => item.code === 'SALAK_LAST_3')
    },
    resultLast2() {
      return this.results.filter(item => item.code === 'SALAK_LAST_2')
    },
    resultNear1st() {
      return this.results.filter(item => item.code === 'SALAK_NEAR_1ST')
    },
    result2nd() {
      return this.results.filter(item => item.code === 'SALAK_2ND')
    },
    result3rd() {
      return this.results.filter(item => item.code === 'SALAK_3RD')
    },
    result4th() {
      return this.results.filter(item => item.code === 'SALAK_4TH')
    },
    result5th() {
      return this.results.filter(item => item.code === 'SALAK_5TH')
    },
  },
  mounted() {
    this.getResult(0)
  },
  methods: {
    async getResult() {
      this.swal.processing()

      try {
        const { data } = await this.axios({
          method: 'get',
          url: 'salak/result',
          params: {
            id: this.$store.state.auth.roundId
          },
        })

        data.data.huayRounds.forEach(item => {
          const date = this.$date(item.closeDate)
          item.name = `${date.format("DD")} ${this.months[parseInt(date.format('MM')) - 1]} ${parseInt(date.format("YYYY")) + 543}`
        })

        this.results = data.data.results


        this.swal.close()
      } catch (e) {
        this.swal.catchError(e)
      }
    },
  },
}
</script>

<style>
.container-sm-lot {
  max-width: 576px;
}

.form-select {
  padding: 3px 15px 3px 15px;
  border-radius: 27px;
  border: 1.5px solid #D1DBE3;
  background: #FFFFFF;
  color: #162276;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.text-warning2 {
  color: #DCA73A;
}

/* .text-blue2 {
  color: #273797;
}


.text-blue3 {
  color: #162276;
} */

.input-search {
  color: #96AABB;
  padding: 6px 15px 6px 15px;
  font-size: 16px;
  border-radius: 27px;
  border: 1.5px solid #D1DBE3;
  width: 274px;
  font-weight: 400;
}

.btn-check-result {
  background: linear-gradient(180deg, #FFFFFF 0%, #D1DBE3 100%);
  box-shadow: 0px 0px 4px 0px #00000040;
  color: #273797;
  font-weight: 600;
}

.btn-check-result:hover {
  color: black;
}

.card-result {
  box-shadow: 0px 0px 6px 0px #00000040;
  border-radius: 22px;
  padding: 15px 20px 15px 20px;
}
</style>
